// global scss file

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
}

p {
  margin-top: 0;
  margin-bottom: 8px;
}
